/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e2994920-d2ea-4e70-967e-3a65a5c11c58",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mpBC5wDk4",
    "aws_user_pools_web_client_id": "kndnf54hsj1lp4grnn31kpcmb",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://kuinqu22zva3lib7kmyntrjqdm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "drinkinggame0524ab8db3c64b8e96a6e67f6d37c269212650-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
