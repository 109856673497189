import logo from './logo.svg';
import './App.css';
import Amplify, { graphqlOperation } from 'aws-amplify'
import awsconfig from './aws-exports'
import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Party from './objects/Party';
import PartyCreator from './objects/PartyCreator';
import Home from './objects/Home';
import Nav from './objects/Nav';


Amplify.configure(awsconfig)

function App() {

  return (
    <Router>
      <div className="App">
          <Nav />
          <Switch>
            <Route path= "/" exact component={Home} />
            <Route path= "/party/:id" component={Party} />
            <Route path= "/partyCreator" component={PartyCreator} />
          </Switch>
      </div>
    </Router>
  );
}


export default withAuthenticator(App);
