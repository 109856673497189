import React from "react";
import TextField from "@material-ui/core/TextField";
import { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { API, graphqlOperation } from "aws-amplify";
import { createTeam, createUser } from "../graphql/mutations";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(1),
	},
}));

function PartyCreator() {
	const history = useHistory();

	const classes = useStyles();

	const [partyName, setPartyName] = useState([""]);

	const [isValid, setIsValid] = React.useState(false);

	const onPartyNameChange = (input) => {
		setPartyName(input);
		if (input.trim() === "") {
			setIsValid(false);
		} else {
			setIsValid(true);
		}
	};

	const createParty = async () => {
		try {
			const term =
				Math.random().toString(36).substring(2, 4) +
				Math.random().toString(36).substring(2, 4);
			// const newUserInput = {name:user, alias:user};
			// const newUser = await API.graphql(graphqlOperation(createUser, {input: newUserInput}));
			const newParty = {
				name: partyName,
				searchTerm: term,
				score: 0,
				round: 0,
				wildNames: [],
				wildDescription: [],
				wildImage: [],
				wildState: [],
				users: [],
				status: "Create",
			};
			const team = await API.graphql(
				graphqlOperation(createTeam, { input: newParty })
			);
			history.push(`/party/${team.data.createTeam.id}`);
		} catch (error) {
			console.log("error creating party", error);
		}
	};

	return (
		<div>
			Create your Team name
			<div>
				<TextField
					id="TeamCreator"
					label="Team Name"
					defaultValue="DRUNKS"
					variant="outlined"
					value={partyName}
					onChange={(input) => onPartyNameChange(input.target.value)}
				/>
			</div>
			<div>
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					endIcon={<SaveIcon />}
					disabled={!isValid}
					onClick={createParty}
				>
					Create
				</Button>
			</div>
		</div>
	);
}

export default PartyCreator;
