/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDrink = /* GraphQL */ `
  query GetDrink($id: ID!) {
    getDrink(id: $id) {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      createdAt
      updatedAt
    }
  }
`;
export const listDrinks = /* GraphQL */ `
  query ListDrinks(
    $filter: ModelDrinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        filePath
        value
        status
        wildAction
        deathAction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGameDrink = /* GraphQL */ `
  query GetGameDrink($id: ID!) {
    getGameDrink(id: $id) {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      round
      createdAt
      updatedAt
    }
  }
`;
export const listGameDrinks = /* GraphQL */ `
  query ListGameDrinks(
    $filter: ModelGameDrinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameDrinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        filePath
        value
        status
        wildAction
        deathAction
        round
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      title
      description
      drinkPoints
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        drinkPoints
        filePath
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeath = /* GraphQL */ `
  query GetDeath($id: ID!) {
    getDeath(id: $id) {
      id
      title
      description
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const listDeaths = /* GraphQL */ `
  query ListDeaths(
    $filter: ModelDeathFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeaths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        filePath
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      alias
      drink
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        alias
        drink
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      searchTerm
      score
      round
      users
      drinkList
      gameDrinks
      d1title
      d1description
      d1filePath
      d1value
      d1status
      d1DeathStatus
      d1DeathTitle
      d1DeathFilePath
      d1DeathDescription
      d2title
      d2description
      d2filePath
      d2value
      d2status
      d2DeathStatus
      d2DeathTitle
      d2DeathFilePath
      d2DeathDescription
      d3title
      d3description
      d3filePath
      d3value
      d3status
      d3DeathStatus
      d3DeathTitle
      d3DeathFilePath
      d3DeathDescription
      d4title
      d4description
      d4filePath
      d4value
      d4status
      d4DeathStatus
      d4DeathTitle
      d4DeathFilePath
      d4DeathDescription
      wildNames
      wildDescription
      wildImage
      wildState
      status
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchTerm
        score
        round
        users
        drinkList
        gameDrinks
        d1title
        d1description
        d1filePath
        d1value
        d1status
        d1DeathStatus
        d1DeathTitle
        d1DeathFilePath
        d1DeathDescription
        d2title
        d2description
        d2filePath
        d2value
        d2status
        d2DeathStatus
        d2DeathTitle
        d2DeathFilePath
        d2DeathDescription
        d3title
        d3description
        d3filePath
        d3value
        d3status
        d3DeathStatus
        d3DeathTitle
        d3DeathFilePath
        d3DeathDescription
        d4title
        d4description
        d4filePath
        d4value
        d4status
        d4DeathStatus
        d4DeathTitle
        d4DeathFilePath
        d4DeathDescription
        wildNames
        wildDescription
        wildImage
        wildState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParty = /* GraphQL */ `
  query GetParty($id: ID!) {
    getParty(id: $id) {
      id
      name
      teams {
        id
        name
        searchTerm
        score
        round
        users
        drinkList
        gameDrinks
        d1title
        d1description
        d1filePath
        d1value
        d1status
        d1DeathStatus
        d1DeathTitle
        d1DeathFilePath
        d1DeathDescription
        d2title
        d2description
        d2filePath
        d2value
        d2status
        d2DeathStatus
        d2DeathTitle
        d2DeathFilePath
        d2DeathDescription
        d3title
        d3description
        d3filePath
        d3value
        d3status
        d3DeathStatus
        d3DeathTitle
        d3DeathFilePath
        d3DeathDescription
        d4title
        d4description
        d4filePath
        d4value
        d4status
        d4DeathStatus
        d4DeathTitle
        d4DeathFilePath
        d4DeathDescription
        wildNames
        wildDescription
        wildImage
        wildState
        status
        createdAt
        updatedAt
      }
      round
      createdAt
      updatedAt
    }
  }
`;
export const listParties = /* GraphQL */ `
  query ListParties(
    $filter: ModelPartyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        teams {
          id
          name
          searchTerm
          score
          round
          users
          drinkList
          gameDrinks
          d1title
          d1description
          d1filePath
          d1value
          d1status
          d1DeathStatus
          d1DeathTitle
          d1DeathFilePath
          d1DeathDescription
          d2title
          d2description
          d2filePath
          d2value
          d2status
          d2DeathStatus
          d2DeathTitle
          d2DeathFilePath
          d2DeathDescription
          d3title
          d3description
          d3filePath
          d3value
          d3status
          d3DeathStatus
          d3DeathTitle
          d3DeathFilePath
          d3DeathDescription
          d4title
          d4description
          d4filePath
          d4value
          d4status
          d4DeathStatus
          d4DeathTitle
          d4DeathFilePath
          d4DeathDescription
          wildNames
          wildDescription
          wildImage
          wildState
          status
          createdAt
          updatedAt
        }
        round
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamBySearchTerm = /* GraphQL */ `
  query GetTeamBySearchTerm(
    $searchTerm: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamBySearchTerm(
      searchTerm: $searchTerm
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchTerm
        score
        round
        users
        drinkList
        gameDrinks
        d1title
        d1description
        d1filePath
        d1value
        d1status
        d1DeathStatus
        d1DeathTitle
        d1DeathFilePath
        d1DeathDescription
        d2title
        d2description
        d2filePath
        d2value
        d2status
        d2DeathStatus
        d2DeathTitle
        d2DeathFilePath
        d2DeathDescription
        d3title
        d3description
        d3filePath
        d3value
        d3status
        d3DeathStatus
        d3DeathTitle
        d3DeathFilePath
        d3DeathDescription
        d4title
        d4description
        d4filePath
        d4value
        d4status
        d4DeathStatus
        d4DeathTitle
        d4DeathFilePath
        d4DeathDescription
        wildNames
        wildDescription
        wildImage
        wildState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
