/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onTeamUpdateById = /* GraphQL */ `
  subscription OnTeamUpdateById($id: ID!) {
    onTeamUpdateById(id: $id) {
      id
      name
      searchTerm
      score
      round
      users
      drinkList
      gameDrinks
      d1title
      d1description
      d1filePath
      d1value
      d1status
      d1DeathStatus
      d1DeathTitle
      d1DeathFilePath
      d1DeathDescription
      d2title
      d2description
      d2filePath
      d2value
      d2status
      d2DeathStatus
      d2DeathTitle
      d2DeathFilePath
      d2DeathDescription
      d3title
      d3description
      d3filePath
      d3value
      d3status
      d3DeathStatus
      d3DeathTitle
      d3DeathFilePath
      d3DeathDescription
      d4title
      d4description
      d4filePath
      d4value
      d4status
      d4DeathStatus
      d4DeathTitle
      d4DeathFilePath
      d4DeathDescription
      wildNames
      wildDescription
      wildImage
      wildState
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDrink = /* GraphQL */ `
  subscription OnCreateDrink {
    onCreateDrink {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDrink = /* GraphQL */ `
  subscription OnUpdateDrink {
    onUpdateDrink {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDrink = /* GraphQL */ `
  subscription OnDeleteDrink {
    onDeleteDrink {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGameDrink = /* GraphQL */ `
  subscription OnCreateGameDrink {
    onCreateGameDrink {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      round
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGameDrink = /* GraphQL */ `
  subscription OnUpdateGameDrink {
    onUpdateGameDrink {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      round
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGameDrink = /* GraphQL */ `
  subscription OnDeleteGameDrink {
    onDeleteGameDrink {
      id
      title
      description
      filePath
      value
      status
      wildAction
      deathAction
      round
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAction = /* GraphQL */ `
  subscription OnCreateAction {
    onCreateAction {
      id
      title
      description
      drinkPoints
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAction = /* GraphQL */ `
  subscription OnUpdateAction {
    onUpdateAction {
      id
      title
      description
      drinkPoints
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAction = /* GraphQL */ `
  subscription OnDeleteAction {
    onDeleteAction {
      id
      title
      description
      drinkPoints
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeath = /* GraphQL */ `
  subscription OnCreateDeath {
    onCreateDeath {
      id
      title
      description
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeath = /* GraphQL */ `
  subscription OnUpdateDeath {
    onUpdateDeath {
      id
      title
      description
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeath = /* GraphQL */ `
  subscription OnDeleteDeath {
    onDeleteDeath {
      id
      title
      description
      filePath
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      alias
      drink
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      alias
      drink
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      alias
      drink
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam {
    onCreateTeam {
      id
      name
      searchTerm
      score
      round
      users
      drinkList
      gameDrinks
      d1title
      d1description
      d1filePath
      d1value
      d1status
      d1DeathStatus
      d1DeathTitle
      d1DeathFilePath
      d1DeathDescription
      d2title
      d2description
      d2filePath
      d2value
      d2status
      d2DeathStatus
      d2DeathTitle
      d2DeathFilePath
      d2DeathDescription
      d3title
      d3description
      d3filePath
      d3value
      d3status
      d3DeathStatus
      d3DeathTitle
      d3DeathFilePath
      d3DeathDescription
      d4title
      d4description
      d4filePath
      d4value
      d4status
      d4DeathStatus
      d4DeathTitle
      d4DeathFilePath
      d4DeathDescription
      wildNames
      wildDescription
      wildImage
      wildState
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam {
    onUpdateTeam {
      id
      name
      searchTerm
      score
      round
      users
      drinkList
      gameDrinks
      d1title
      d1description
      d1filePath
      d1value
      d1status
      d1DeathStatus
      d1DeathTitle
      d1DeathFilePath
      d1DeathDescription
      d2title
      d2description
      d2filePath
      d2value
      d2status
      d2DeathStatus
      d2DeathTitle
      d2DeathFilePath
      d2DeathDescription
      d3title
      d3description
      d3filePath
      d3value
      d3status
      d3DeathStatus
      d3DeathTitle
      d3DeathFilePath
      d3DeathDescription
      d4title
      d4description
      d4filePath
      d4value
      d4status
      d4DeathStatus
      d4DeathTitle
      d4DeathFilePath
      d4DeathDescription
      wildNames
      wildDescription
      wildImage
      wildState
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam {
    onDeleteTeam {
      id
      name
      searchTerm
      score
      round
      users
      drinkList
      gameDrinks
      d1title
      d1description
      d1filePath
      d1value
      d1status
      d1DeathStatus
      d1DeathTitle
      d1DeathFilePath
      d1DeathDescription
      d2title
      d2description
      d2filePath
      d2value
      d2status
      d2DeathStatus
      d2DeathTitle
      d2DeathFilePath
      d2DeathDescription
      d3title
      d3description
      d3filePath
      d3value
      d3status
      d3DeathStatus
      d3DeathTitle
      d3DeathFilePath
      d3DeathDescription
      d4title
      d4description
      d4filePath
      d4value
      d4status
      d4DeathStatus
      d4DeathTitle
      d4DeathFilePath
      d4DeathDescription
      wildNames
      wildDescription
      wildImage
      wildState
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateParty = /* GraphQL */ `
  subscription OnCreateParty {
    onCreateParty {
      id
      name
      teams {
        id
        name
        searchTerm
        score
        round
        users
        drinkList
        gameDrinks
        d1title
        d1description
        d1filePath
        d1value
        d1status
        d1DeathStatus
        d1DeathTitle
        d1DeathFilePath
        d1DeathDescription
        d2title
        d2description
        d2filePath
        d2value
        d2status
        d2DeathStatus
        d2DeathTitle
        d2DeathFilePath
        d2DeathDescription
        d3title
        d3description
        d3filePath
        d3value
        d3status
        d3DeathStatus
        d3DeathTitle
        d3DeathFilePath
        d3DeathDescription
        d4title
        d4description
        d4filePath
        d4value
        d4status
        d4DeathStatus
        d4DeathTitle
        d4DeathFilePath
        d4DeathDescription
        wildNames
        wildDescription
        wildImage
        wildState
        status
        createdAt
        updatedAt
      }
      round
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateParty = /* GraphQL */ `
  subscription OnUpdateParty {
    onUpdateParty {
      id
      name
      teams {
        id
        name
        searchTerm
        score
        round
        users
        drinkList
        gameDrinks
        d1title
        d1description
        d1filePath
        d1value
        d1status
        d1DeathStatus
        d1DeathTitle
        d1DeathFilePath
        d1DeathDescription
        d2title
        d2description
        d2filePath
        d2value
        d2status
        d2DeathStatus
        d2DeathTitle
        d2DeathFilePath
        d2DeathDescription
        d3title
        d3description
        d3filePath
        d3value
        d3status
        d3DeathStatus
        d3DeathTitle
        d3DeathFilePath
        d3DeathDescription
        d4title
        d4description
        d4filePath
        d4value
        d4status
        d4DeathStatus
        d4DeathTitle
        d4DeathFilePath
        d4DeathDescription
        wildNames
        wildDescription
        wildImage
        wildState
        status
        createdAt
        updatedAt
      }
      round
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteParty = /* GraphQL */ `
  subscription OnDeleteParty {
    onDeleteParty {
      id
      name
      teams {
        id
        name
        searchTerm
        score
        round
        users
        drinkList
        gameDrinks
        d1title
        d1description
        d1filePath
        d1value
        d1status
        d1DeathStatus
        d1DeathTitle
        d1DeathFilePath
        d1DeathDescription
        d2title
        d2description
        d2filePath
        d2value
        d2status
        d2DeathStatus
        d2DeathTitle
        d2DeathFilePath
        d2DeathDescription
        d3title
        d3description
        d3filePath
        d3value
        d3status
        d3DeathStatus
        d3DeathTitle
        d3DeathFilePath
        d3DeathDescription
        d4title
        d4description
        d4filePath
        d4value
        d4status
        d4DeathStatus
        d4DeathTitle
        d4DeathFilePath
        d4DeathDescription
        wildNames
        wildDescription
        wildImage
        wildState
        status
        createdAt
        updatedAt
      }
      round
      createdAt
      updatedAt
    }
  }
`;
