import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from "react-router-dom";
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';


const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));



function Nav() {
    const history = useHistory();
    
    const classes = useStyles();
    
    function handleClick() {
        history.push("/");
    }
      
      
    return (

<div className={classes.root}>
<AppBar position="static">
  <Toolbar>
       <Fab size="small" color="secondary" aria-label="home" className={classes.margin}
            onClick={handleClick}>
            <HomeIcon />
            </Fab>
  </Toolbar>
</AppBar>
</div>
    );
}

export default Nav;