import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from  '../graphql/queries'
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";



function Home() {

    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        margin: {
          margin: theme.spacing(5),
        },
        extendedIcon: {
          marginRight: theme.spacing(1),
        },
        bottom: {
            marginBottom: theme.bottom

        },
        stickToBottom: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
      }))

    //   useEffect(() => {

    //   }, []);

    const classes = useStyles();

    const [partyId, setPartyId] = useState([]);

    const [team, setTeam] = useState([]);

    const [teamFound, setTeamFound] = useState(false);

    const [hasSearched, setHasSearched] = useState(false);

    const onPartyIdChange = input => {
        setPartyId(input);
        setTeamFound(false);
        setTeam('');
        console.log(team);
        console.log(teamFound);
    };

    const searchForParty = async () =>  {
        setHasSearched(true);
        try {
            const searchTeam = await API.graphql(graphqlOperation(queries.getTeamBySearchTerm, { searchTerm: partyId}));
            if(searchTeam.data.getTeamBySearchTerm.items.length > 0 ) {
                setTeam(searchTeam.data.getTeamBySearchTerm.items[0])
                setTeamFound(true);
            } else {
                setTeam('');
                setTeamFound(false);
            }
            console.log(searchTeam)
        } catch (error) {
            console.log(error);
        }
    }

    function goToParty() {
        history.push(`/party/${team.id}`);
    }
    
    return (
        <div>
            <div>
                Welcome Home
            </div>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
                >
                <Link to={'/partyCreator'}>
                    <Fab variant="extended" color="primary" className={classes.margin}>
                        <NavigationIcon className={classes.extendedIcon} />
                            Create Party
                    </Fab>
                </Link>
                <Paper component="form" className={classes.root}>
                    <InputBase
                            className={classes.input}
                            placeholder="Search for Party"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            value={partyId}
                            onChange= {input => onPartyIdChange(input.target.value)}
                        />
                        <IconButton 
                            className={classes.iconButton} 
                            aria-label="search"
                            onClick={searchForParty}
                        >
                            <SearchIcon />
                        </IconButton>
                </Paper>
                    <Fab variant="extended" 
                    color="primary" 
                    className={classes.margin} 
                    disabled={!teamFound}
                    onClick={goToParty}>
                        <NavigationIcon className={classes.extendedIcon} />
                            Join Party
                    </Fab>
                    <div>
                        {(!teamFound  && hasSearched) &&
                            'No party found'
                        }
                    </div>
                    <AmplifySignOut />
            </Grid>
        </div>
    )
}

export default Home;