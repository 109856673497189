import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import * as mutations from "../graphql/mutations";
import GroupIcon from "@material-ui/icons/Group";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import ExposureNeg1Icon from "@material-ui/icons/ExposureNeg1";
import Switch from "@material-ui/core/Switch";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import Filter4Icon from "@material-ui/icons/Filter4";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around",
		backgroundColor: theme.palette.background.paper,
	},
	photo: {
		maxHeight: "50",
		maxWidth: "50",
	},
	fill: {
		width: "100%",
		height: "100%",
	},
	fillCompleted: {
		width: "100%",
		height: "100%",
		backgroundColor: "#b2fab4",
	},
	stickToBottom: {
		width: "100%",
		position: "fixed",
		bottom: 0,
	},
	stickToBottom: {
		width: "100%",
		position: "fixed",
		bottom: 0,
	},
	lowerScreen: {
		width: "100%",
		position: "fixed",
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	pos: {
		marginBottom: 12,
	},
	stickToRight: {
		margin: "0 2px",
		position: "absolute",
		right: 0,
	},
	stickToLeft: {
		margin: "0 2px",
		left: 0,
	},
	headerName: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.primary,
	},
	header: {
		display: "flex",
		alignItems: "center",
		height: 50,
		paddingLeft: theme.spacing(4),
		backgroundColor: theme.palette.background.default,
	},
	img: {
		height: 250,
		display: "block",
		maxWidth: 400,
		width: "100%",
	},
	imageList: {
		flexWrap: "nowrap",
		height: 300,
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: "translateZ(0)",
	},
	title: {
		color: theme.palette.primary.light,
	},
	subtitle: {
		whiteSpace: "unset",
	},
	titleBar: {
		background:
			"linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
	},
}));

function Party({ match }) {
	const theme = useTheme();

	const [user, setUser] = useState([]);

	const classes = useStyles();

	const [value, setValue] = React.useState("home");

	const [team, setTeam] = useState([]);

	const [player1, setPlayer1] = React.useState("Player1");

	const [player2, setPlayer2] = React.useState("Player2");

	const [player3, setPlayer3] = React.useState("Player3");

	const [player4, setPlayer4] = React.useState("Player4");

	const [anchorEl, setAnchorEl] = React.useState(null);

	const [anchorElSwap, setAnchorElSwap] = React.useState(null);

	const [image1, setImage1] = React.useState(null);

	const [image2, setImage2] = React.useState(null);

	const [image3, setImage3] = React.useState(null);

	const [image4, setImage4] = React.useState(null);

	const [imageD1, setImageD1] = React.useState(null);

	const [imageD2, setImageD2] = React.useState(null);

	const [imageD3, setImageD3] = React.useState(null);

	const [imageD4, setImageD4] = React.useState(null);

	const [imageDrink, setImageDrink] = React.useState(null);

	const [imageDeath, setImageDeath] = React.useState(null);

	const [switchState, setState] = React.useState({
		checked1: false,
		checked2: false,
		checked3: false,
		checked4: false,
		checkedHome: false,
	});

	const [itemData, setItemData] = React.useState({});

	const handleOptionsClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleOptionsClose = () => {
		setAnchorEl(null);
	};

	const handleSwapClick = (event) => {
		setAnchorElSwap(event.currentTarget);
	};

	const handleSwapClose = () => {
		setAnchorElSwap(null);
	};

	const handleSwitch = (event) => {
		setState({ ...switchState, [event.target.name]: event.target.checked });
	};

	const updateActionStatus = (event) => {
		let index = team.wildNames.indexOf(event.currentTarget.name);
		let updatedTeam = team;
		console.log("prev state", updatedTeam.wildState[index]);
		console.log("name", updatedTeam.wildNames[index]);
		console.log("names", updatedTeam.wildNames);
		console.log("itemdata", itemData);
		updatedTeam.wildState[index] =
			updatedTeam.wildState[index] === "true" ? false : true;
		updateTeam(updatedTeam);
		console.log("new state", updatedTeam.wildState[index]);
	};

	const handleD1CompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d1status = true;
		updatedTeam.score = updatedTeam.score + updatedTeam.d1value;
		updateTeam(updatedTeam);
	};

	const handleD1UnCompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d1status = false;
		updatedTeam.score = updatedTeam.score - updatedTeam.d1value;
		updateTeam(updatedTeam);
	};

	const handleD2CompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d2status = true;
		updatedTeam.score = updatedTeam.score + updatedTeam.d2value;
		updateTeam(updatedTeam);
	};

	const handleD2UnCompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d2status = false;
		updatedTeam.score = updatedTeam.score - updatedTeam.d2value;
		updateTeam(updatedTeam);
	};

	const handleD3CompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d3status = true;
		updatedTeam.score = updatedTeam.score + updatedTeam.d3value;
		updateTeam(updatedTeam);
	};

	const handleD3UnCompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d3status = false;
		updatedTeam.score = updatedTeam.score - updatedTeam.d3value;
		updateTeam(updatedTeam);
	};

	const handleD4CompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d4status = true;
		updatedTeam.score = updatedTeam.score + updatedTeam.d4value;
		updateTeam(updatedTeam);
	};

	const handleD4UnCompleteDrink = () => {
		let updatedTeam = team;
		updatedTeam.d4status = false;
		updatedTeam.score = updatedTeam.score - updatedTeam.d4value;
		updateTeam(updatedTeam);
	};

	const handleSwapPlayer1AndPlayer2 = () => {
		const holdD1title = team.d1title;
		const holdD1description = team.d1description;
		const holdD1filePath = team.d1filePath;
		const holdD1value = team.d1value;
		const holdD1status = team.d1status;

		const updatedTeam = team;
		updatedTeam.d1title = updatedTeam.d2title;
		updatedTeam.d1description = updatedTeam.d2description;
		updatedTeam.d1filePath = updatedTeam.d2filePath;
		updatedTeam.d1status = updatedTeam.d2status;
		updatedTeam.d1value = updatedTeam.d2value;

		updatedTeam.d2title = holdD1title;
		updatedTeam.d2description = holdD1description;
		updatedTeam.d2filePath = holdD1filePath;
		updatedTeam.d2status = holdD1status;
		updatedTeam.d2value = holdD1value;
		updateTeam(updatedTeam);
	};

	const handleSwapPlayer1AndPlayer3 = () => {
		const holdD1title = team.d1title;
		const holdD1description = team.d1description;
		const holdD1filePath = team.d1filePath;
		const holdD1value = team.d1value;
		const holdD1status = team.d1status;

		const updatedTeam = team;
		updatedTeam.d1title = updatedTeam.d3title;
		updatedTeam.d1description = updatedTeam.d3description;
		updatedTeam.d1filePath = updatedTeam.d3filePath;
		updatedTeam.d1status = updatedTeam.d3status;
		updatedTeam.d1value = updatedTeam.d3value;

		updatedTeam.d3title = holdD1title;
		updatedTeam.d3description = holdD1description;
		updatedTeam.d3filePath = holdD1filePath;
		updatedTeam.d3status = holdD1status;
		updatedTeam.d3value = holdD1value;
		updateTeam(updatedTeam);
	};

	const handleSwapPlayer1AndPlayer4 = () => {
		const holdD1title = team.d1title;
		const holdD1description = team.d1description;
		const holdD1filePath = team.d1filePath;
		const holdD1value = team.d1value;
		const holdD1status = team.d1status;

		const updatedTeam = team;
		updatedTeam.d1title = updatedTeam.d4title;
		updatedTeam.d1description = updatedTeam.d4description;
		updatedTeam.d1filePath = updatedTeam.d4filePath;
		updatedTeam.d1status = updatedTeam.d4status;
		updatedTeam.d1value = updatedTeam.d4value;

		updatedTeam.d4title = holdD1title;
		updatedTeam.d4description = holdD1description;
		updatedTeam.d4filePath = holdD1filePath;
		updatedTeam.d4status = holdD1status;
		updatedTeam.d4value = holdD1value;
		updateTeam(updatedTeam);
	};

	const handleSwapPlayer2AndPlayer3 = () => {
		const holdD2title = team.d2title;
		const holdD2description = team.d2description;
		const holdD2filePath = team.d2filePath;
		const holdD2value = team.d2value;
		const holdD2status = team.d2status;

		const updatedTeam = team;
		updatedTeam.d2title = updatedTeam.d3title;
		updatedTeam.d2description = updatedTeam.d3description;
		updatedTeam.d2filePath = updatedTeam.d3filePath;
		updatedTeam.d2status = updatedTeam.d3status;
		updatedTeam.d2value = updatedTeam.d3value;

		updatedTeam.d3title = holdD2title;
		updatedTeam.d3description = holdD2description;
		updatedTeam.d3filePath = holdD2filePath;
		updatedTeam.d3status = holdD2status;
		updatedTeam.d3value = holdD2value;
		updateTeam(updatedTeam);
	};

	const handleSwapPlayer2AndPlayer4 = () => {
		const holdD2title = team.d2title;
		const holdD2description = team.d2description;
		const holdD2filePath = team.d2filePath;
		const holdD2value = team.d2value;
		const holdD2status = team.d2status;

		const updatedTeam = team;
		updatedTeam.d2title = updatedTeam.d4title;
		updatedTeam.d2description = updatedTeam.d4description;
		updatedTeam.d2filePath = updatedTeam.d4filePath;
		updatedTeam.d2status = updatedTeam.d4status;
		updatedTeam.d2value = updatedTeam.d4value;

		updatedTeam.d4title = holdD2title;
		updatedTeam.d4description = holdD2description;
		updatedTeam.d4filePath = holdD2filePath;
		updatedTeam.d4status = holdD2status;
		updatedTeam.d4value = holdD2value;
		updateTeam(updatedTeam);
	};

	const handleSwapPlayer3AndPlayer4 = () => {
		const holdD3title = team.d3title;
		const holdD3description = team.d3description;
		const holdD3filePath = team.d3filePath;
		const holdD3value = team.d3value;
		const holdD3status = team.d3status;

		const updatedTeam = team;
		updatedTeam.d3title = updatedTeam.d4title;
		updatedTeam.d3description = updatedTeam.d4description;
		updatedTeam.d3filePath = updatedTeam.d4filePath;
		updatedTeam.d3status = updatedTeam.d4status;
		updatedTeam.d3value = updatedTeam.d4value;

		updatedTeam.d4title = holdD3title;
		updatedTeam.d4description = holdD3description;
		updatedTeam.d4filePath = holdD3filePath;
		updatedTeam.d4status = holdD3status;
		updatedTeam.d4value = holdD3value;
		updateTeam(updatedTeam);
	};

	useEffect(() => {
		subscribe();
		Auth.currentAuthenticatedUser({
			bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		})
			.then((thisUser) => {
				initTeam(thisUser.username);
			})
			.catch((err) => console.log(err));
	}, []);

	function subscribe() {
		const sub = API.graphql(
			graphqlOperation(subscriptions.onTeamUpdateById, { id: match.params.id })
		).subscribe({
			next: (teamData) => {
				if (teamData.value.data.onTeamUpdateById !== undefined) {
					setTeam(teamData.value.data.onTeamUpdateById);
					setUsers(teamData.value.data.onTeamUpdateById.users);
					renderImages(teamData.value.data.onTeamUpdateById);
					addWildData(teamData.value.data.onTeamUpdateById);
				}
			},
			error: (error) => console.warn(error),
		});
	}

	function setUsers(users) {
		if (users != undefined) {
			users[0] !== undefined ? setPlayer1(users[0]) : setPlayer1("Player1");
			users[1] !== undefined ? setPlayer2(users[1]) : setPlayer2("Player2");
			users[2] !== undefined ? setPlayer3(users[2]) : setPlayer3("Player3");
			users[3] !== undefined ? setPlayer4(users[3]) : setPlayer4("Player4");
		}
	}

	const initTeam = async (userName) => {
		try {
			setUser(userName);
			const searchTeam = await API.graphql(
				graphqlOperation(queries.getTeam, { id: match.params.id })
			);
			setTeam(searchTeam.data.getTeam);
			if (
				searchTeam.data.getTeam.users.length < 4 &&
				!searchTeam.data.getTeam.users.includes(userName)
			) {
				addUser(searchTeam.data.getTeam, userName);
			}
			renderImages(searchTeam.data.getTeam);
			setUsers(searchTeam.data.getTeam.users);
			addWildData(searchTeam.data.getTeam);
		} catch (error) {
			console.log(error);
		}
	};

	const addUser = async (freshTeam, userName) => {
		freshTeam.users.push(userName);
		freshTeam.createdAt = undefined;
		freshTeam.updatedAt = undefined;
		try {
			const team = await API.graphql(
				graphqlOperation(mutations.updateTeam, { input: freshTeam })
			);
		} catch (error) {
			console.log(error);
		}
	};

	function addWild(updatedTeam, wildActionList) {
		let randomWildActionNum = Math.floor(Math.random() * wildActionList.length);
		let roundWildAction = wildActionList[randomWildActionNum];
		if (!updatedTeam.wildNames.includes(roundWildAction.title)) {
			updatedTeam.wildNames.push(roundWildAction.title);
			updatedTeam.wildDescription.push(roundWildAction.description);
			updatedTeam.wildImage.push(roundWildAction.filePath);
			updatedTeam.wildState.push(roundWildAction.status);
			wildActionList.splice(randomWildActionNum, 1);
			return updatedTeam;
		} else {
			wildActionList.splice(randomWildActionNum, 1);
			addWild(updatedTeam, wildActionList);
		}
		return updatedTeam;
	}

	const getNewDrinks = async () => {
		try {
			const listDrinks = await API.graphql(
				graphqlOperation(queries.listDrinks)
			);
			const listDeathActions = await API.graphql(
				graphqlOperation(queries.listDeaths)
			);
			const listWildActions = await API.graphql(
				graphqlOperation(queries.listActions)
			);
			const drinkList = listDrinks.data.listDrinks.items;
			const deathActionList = listDeathActions.data.listDeaths.items;
			const wildActionList = listWildActions.data.listActions.items;

			let randomNum = Math.floor(Math.random() * drinkList.length);
			let randomDeathActionNum = Math.floor(
				Math.random() * deathActionList.length
			);
			let randomWildActionNum = Math.floor(
				Math.random() * wildActionList.length
			);

			let roundDrink = drinkList[randomNum];
			let roundDeathAction = deathActionList[randomDeathActionNum];
			let updatedTeam = team;
			if (roundDrink.deathAction) {
				updatedTeam.d1DeathStatus = false;
				updatedTeam.d1DeathTitle = roundDeathAction.title;
				updatedTeam.d1DeathFilePath = roundDeathAction.filePath;
				updatedTeam.d1DeathDescription = roundDeathAction.description;
				deathActionList.splice(randomDeathActionNum, 1);
				randomDeathActionNum = Math.floor(
					Math.random() * deathActionList.length
				);
			} else {
				updatedTeam.d1DeathStatus = null;
				updatedTeam.d1DeathTitle = null;
				updatedTeam.d1DeathFilePath = null;
				updatedTeam.d1DeathDescription = null;
			}
			if (roundDrink.wildAction) {
				updatedTeam = addWild(updatedTeam, wildActionList);
			}
			updatedTeam.d1title = roundDrink.title;
			updatedTeam.d1description = roundDrink.description;
			updatedTeam.d1filePath = roundDrink.filePath;
			updatedTeam.d1value = roundDrink.value;
			updatedTeam.d1status = roundDrink.status;
			drinkList.splice(randomNum, 1);

			randomNum = Math.floor(Math.random() * drinkList.length);
			roundDrink = drinkList[randomNum];
			roundDeathAction = deathActionList[randomDeathActionNum];

			if (roundDrink.deathAction) {
				updatedTeam.d2DeathStatus = false;
				updatedTeam.d2DeathTitle = roundDeathAction.title;
				updatedTeam.d2DeathFilePath = roundDeathAction.filePath;
				updatedTeam.d2DeathDescription = roundDeathAction.description;
				deathActionList.splice(randomDeathActionNum, 1);
				randomDeathActionNum = Math.floor(
					Math.random() * deathActionList.length
				);
			} else {
				updatedTeam.d2DeathStatus = null;
				updatedTeam.d2DeathTitle = null;
				updatedTeam.d2DeathFilePath = null;
				updatedTeam.d2DeathDescription = null;
			}
			if (roundDrink.wildAction) {
				updatedTeam = addWild(updatedTeam, wildActionList);
			}
			updatedTeam.d2title = roundDrink.title;
			updatedTeam.d2description = roundDrink.description;
			updatedTeam.d2filePath = roundDrink.filePath;
			updatedTeam.d2value = roundDrink.value;
			updatedTeam.d2status = roundDrink.status;
			drinkList.splice(randomNum, 1);

			randomNum = Math.floor(Math.random() * drinkList.length);
			roundDrink = drinkList[randomNum];
			roundDeathAction = deathActionList[randomDeathActionNum];

			if (roundDrink.deathAction) {
				updatedTeam.d3DeathStatus = false;
				updatedTeam.d3DeathTitle = roundDeathAction.title;
				updatedTeam.d3DeathFilePath = roundDeathAction.filePath;
				updatedTeam.d3DeathDescription = roundDeathAction.description;
				deathActionList.splice(randomDeathActionNum, 1);
				randomDeathActionNum = Math.floor(
					Math.random() * deathActionList.length
				);
			} else {
				updatedTeam.d3DeathStatus = null;
				updatedTeam.d3DeathTitle = null;
				updatedTeam.d3DeathFilePath = null;
				updatedTeam.d3DeathDescription = null;
			}
			if (roundDrink.wildAction) {
				updatedTeam = addWild(updatedTeam, wildActionList);
			}
			updatedTeam.d3title = roundDrink.title;
			updatedTeam.d3description = roundDrink.description;
			updatedTeam.d3filePath = roundDrink.filePath;
			updatedTeam.d3value = roundDrink.value;
			updatedTeam.d3status = roundDrink.status;
			drinkList.splice(randomNum, 1);

			randomNum = Math.floor(Math.random() * drinkList.length);
			roundDrink = drinkList[randomNum];
			roundDeathAction = deathActionList[randomDeathActionNum];

			if (roundDrink.deathAction) {
				updatedTeam.d4DeathStatus = false;
				updatedTeam.d4DeathTitle = roundDeathAction.title;
				updatedTeam.d4DeathFilePath = roundDeathAction.filePath;
				updatedTeam.d4DeathDescription = roundDeathAction.description;
				deathActionList.splice(randomDeathActionNum, 1);
				randomDeathActionNum = Math.floor(
					Math.random() * deathActionList.length
				);
			} else {
				updatedTeam.d4DeathStatus = null;
				updatedTeam.d4DeathTitle = null;
				updatedTeam.d4DeathFilePath = null;
				updatedTeam.d4DeathDescription = null;
			}
			if (roundDrink.wildAction) {
				updatedTeam = addWild(updatedTeam, wildActionList);
			}
			updatedTeam.d4title = roundDrink.title;
			updatedTeam.d4description = roundDrink.description;
			updatedTeam.d4filePath = roundDrink.filePath;
			updatedTeam.d4value = roundDrink.value;
			updatedTeam.d4status = roundDrink.status;

			updatedTeam.round = updatedTeam.round + 1;
			updatedTeam.status = "In Progress";
			drinkList.splice(randomNum, 1);
			addWildData(updatedTeam);
			updateTeam(updatedTeam);
			renderImages(updatedTeam);
		} catch (error) {
			console.log(error);
		}
	};

	const addWildData = async (updatedTeam) => {
		let wildData = itemData;
		for (let i = 0; i < updatedTeam.wildNames.length; i++) {
			const newWild = {
				img: await getImage(updatedTeam.wildImage[i]),
				title: updatedTeam.wildNames[i],
				description: updatedTeam.wildDescription[i],
				state: updatedTeam.wildState[i],
			};
			wildData[updatedTeam.wildNames[i]] = newWild;
		}
		setItemData(wildData);
	};

	const renderImages = async (updatedTeam) => {
		try {
			setImage1(await getImage(updatedTeam.d1filePath));
			setImage2(await getImage(updatedTeam.d2filePath));
			setImage3(await getImage(updatedTeam.d3filePath));
			setImage4(await getImage(updatedTeam.d4filePath));
			setImageD1(await getImage(updatedTeam.d1DeathFilePath));
			setImageD2(await getImage(updatedTeam.d2DeathFilePath));
			setImageD3(await getImage(updatedTeam.d3DeathFilePath));
			setImageD4(await getImage(updatedTeam.d4DeathFilePath));
			setImageDrink(await getImage("Drink Cards/Drink Card Logo.png"));
			setImageDeath(await getImage("Death Cards/Death Card Icon.png"));
		} catch (error) {
			console.log(error);
		}
	};

	const getImage = async (path) => {
		try {
			const image = await Storage.get(path, { expires: 60 });
			return image;
		} catch (error) {
			console.log(error);
		}
	};

	const updateTeam = async (updatedTeam) => {
		updatedTeam.createdAt = undefined;
		updatedTeam.updatedAt = undefined;
		try {
			await API.graphql(
				graphqlOperation(mutations.updateTeam, { input: updatedTeam })
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const addP1Value = async () => {
		const updatedTeam = team;
		updatedTeam.d1value = updatedTeam.d1value + 1;
		updateTeam(updatedTeam);
	};

	const subtractP1Value = async () => {
		const updatedTeam = team;
		updatedTeam.d1value = updatedTeam.d1value - 1;
		updateTeam(updatedTeam);
	};

	const addP2Value = async () => {
		const updatedTeam = team;
		updatedTeam.d2value = updatedTeam.d2value + 1;
		updateTeam(updatedTeam);
	};

	const subtractP2Value = async () => {
		const updatedTeam = team;
		updatedTeam.d2value = updatedTeam.d2value - 1;
		updateTeam(updatedTeam);
	};

	const addP3Value = async () => {
		const updatedTeam = team;
		updatedTeam.d3value = updatedTeam.d3value + 1;
		updateTeam(updatedTeam);
	};

	const subtractP3Value = async () => {
		const updatedTeam = team;
		updatedTeam.d3value = updatedTeam.d3value - 1;
		updateTeam(updatedTeam);
	};

	const addP4Value = async () => {
		const updatedTeam = team;
		updatedTeam.d4value = updatedTeam.d4value + 1;
		updateTeam(updatedTeam);
	};

	const subtractP4Value = async () => {
		const updatedTeam = team;
		updatedTeam.d4value = updatedTeam.dvalue - 1;
		updateTeam(updatedTeam);
	};

	const addScoreValue = async () => {
		const updatedTeam = team;
		updatedTeam.score = updatedTeam.score + 1;
		updateTeam(updatedTeam);
	};

	const subtractScoreValue = async () => {
		const updatedTeam = team;
		updatedTeam.score = updatedTeam.score - 1;
		updateTeam(updatedTeam);
	};

	function firstCard() {
		return (
			<div>
				<Card
					className={team.d1status ? classes.fillCompleted : classes.fill}
					variant="outlined"
				>
					<CardContent>
						<img width="50" height="60" src={imageDrink} />
						<Grid container spacing={3}>
							<Grid></Grid>
							<Switch
								checked={switchState.checked1}
								onChange={handleSwitch}
								color="primary"
								name="checked1"
								inputProps={{ "aria-label": "primary checkbox" }}
							/>
						</Grid>
						<Typography variant="h5" component="h2">
							{team.d1title}
						</Typography>
						<Typography className={classes.pos} color="textSecondary">
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={subtractP1Value}
								disabled={!switchState.checked1 || team.d1status}
							>
								<ExposureNeg1Icon />
							</IconButton>
							{team.d1value} point(s)
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={addP1Value}
								disabled={!switchState.checked1 || team.d1status}
							>
								<PlusOneIcon />
							</IconButton>
						</Typography>
						<Typography variant="body2" component="p">
							{team.d1description}
						</Typography>
						<img width="200" height="200" src={image1} />
					</CardContent>
					<CardActions>
						<div>
							<Button
								className={classes.stickToLeft}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleOptionsClick}
							>
								Open Menu
							</Button>
							<Menu
								id="options-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleOptionsClose}
							>
								<MenuItem
									onClick={handleD1CompleteDrink}
									disabled={team.d1status}
								>
									Set card to complete
								</MenuItem>
								<MenuItem
									onClick={handleD1UnCompleteDrink}
									disabled={!team.d1status}
								>
									Set card to incomplete
								</MenuItem>
							</Menu>
							<Button
								className={classes.stickToRight}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleSwapClick}
							>
								Swap Menu
							</Button>
							<Menu
								id="swap-menu"
								anchorEl={anchorElSwap}
								keepMounted
								open={Boolean(anchorElSwap)}
								onClose={handleSwapClose}
							>
								<MenuItem onClick={handleSwapPlayer1AndPlayer2}>
									Swap {player2}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer1AndPlayer3}>
									Swap {player3}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer1AndPlayer4}>
									Swap {player4}
								</MenuItem>
							</Menu>
						</div>
					</CardActions>
				</Card>
				{team.d1DeathTitle != null && firstDeathCard()}
			</div>
		);
	}

	function firstDeathCard() {
		return (
			<Card className={classes.root}>
				<CardContent>
					<img width="50" height="60" src={imageDeath} />
					<Typography className={classes.pos} color="textSecondary">
						{team.d1DeathTitle}
					</Typography>
					<Typography variant="body2" component="p">
						{team.d1DeathDescription}
					</Typography>
					<img width="200" height="200" src={imageD1} />
				</CardContent>
			</Card>
		);
	}

	function secondCard() {
		return (
			<div>
				<Card
					className={team.d2status ? classes.fillCompleted : classes.fill}
					variant="outlined"
				>
					<CardContent>
						<img width="50" height="60" src={imageDrink} />
						<Grid container spacing={3}>
							<Grid></Grid>
							<Switch
								checked={switchState.checked2}
								onChange={handleSwitch}
								color="primary"
								name="checked2"
								inputProps={{ "aria-label": "primary checkbox" }}
							/>
						</Grid>
						<Typography variant="h5" component="h2">
							{team.d2title}
						</Typography>
						<Typography className={classes.pos} color="textSecondary">
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={subtractP2Value}
								disabled={!switchState.checked2 || team.d2status}
							>
								<ExposureNeg1Icon />
							</IconButton>
							{team.d2value} point(s)
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={addP2Value}
								disabled={!switchState.checked2 || team.d2status}
							>
								<PlusOneIcon />
							</IconButton>
						</Typography>
						<Typography variant="body2" component="p">
							{team.d2description}
						</Typography>
						<img width="200" height="200" src={image2} />
					</CardContent>
					<CardActions>
						<div>
							<Button
								className={classes.stickToLeft}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleOptionsClick}
							>
								Open Menu
							</Button>
							<Menu
								id="options-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleOptionsClose}
							>
								<MenuItem
									onClick={handleD2CompleteDrink}
									disabled={team.d2status}
								>
									Set card to complete
								</MenuItem>
								<MenuItem
									onClick={handleD2UnCompleteDrink}
									disabled={!team.d2status}
								>
									Set card to incomplete
								</MenuItem>
							</Menu>
							<Button
								className={classes.stickToRight}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleSwapClick}
							>
								Swap Menu
							</Button>
							<Menu
								id="swap-menu"
								anchorEl={anchorElSwap}
								keepMounted
								open={Boolean(anchorElSwap)}
								onClose={handleSwapClose}
							>
								<MenuItem onClick={handleSwapPlayer1AndPlayer2}>
									Swap {player1}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer2AndPlayer3}>
									Swap {player3}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer2AndPlayer4}>
									Swap {player4}
								</MenuItem>
							</Menu>
						</div>
					</CardActions>
				</Card>
				{team.d2DeathTitle != null && secondDeathCard()}
			</div>
		);
	}

	function secondDeathCard() {
		return (
			<Card className={classes.root}>
				<CardContent>
					<img width="50" height="60" src={imageDeath} />
					<Typography className={classes.pos} color="textSecondary">
						{team.d2DeathTitle}
					</Typography>
					<Typography variant="body2" component="p">
						{team.d2DeathDescription}
					</Typography>
					<img width="200" height="200" src={imageD2} />
				</CardContent>
			</Card>
		);
	}

	function thirdCard() {
		return (
			<div>
				<Card
					className={team.d3status ? classes.fillCompleted : classes.fill}
					variant="outlined"
				>
					<CardContent>
						<img width="50" height="60" src={imageDrink} />
						<Grid container spacing={3}>
							<Grid></Grid>
							<Switch
								checked={switchState.checked3}
								onChange={handleSwitch}
								color="primary"
								name="checked3"
								inputProps={{ "aria-label": "primary checkbox" }}
							/>
						</Grid>
						<Typography variant="h5" component="h2">
							{team.d3title}
						</Typography>
						<Typography className={classes.pos} color="textSecondary">
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={subtractP3Value}
								disabled={!switchState.checked3 || team.d3status}
							>
								<ExposureNeg1Icon />
							</IconButton>
							{team.d3value} point(s)
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={addP3Value}
								disabled={!switchState.checked3 || team.d3status}
							>
								<PlusOneIcon />
							</IconButton>
						</Typography>
						<Typography variant="body2" component="p">
							{team.d3description}
						</Typography>
						<img width="200" height="200" src={image3} />
					</CardContent>
					<CardActions>
						<div>
							<Button
								className={classes.stickToLeft}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleOptionsClick}
							>
								Open Menu
							</Button>
							<Menu
								id="options-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleOptionsClose}
							>
								<MenuItem
									onClick={handleD3CompleteDrink}
									disabled={team.d3status}
								>
									Set card to complete
								</MenuItem>
								<MenuItem
									onClick={handleD3UnCompleteDrink}
									disabled={!team.d3status}
								>
									Set card to incomplete
								</MenuItem>
							</Menu>
							<Button
								className={classes.stickToRight}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleSwapClick}
							>
								Swap Menu
							</Button>
							<Menu
								id="swap-menu"
								anchorEl={anchorElSwap}
								keepMounted
								open={Boolean(anchorElSwap)}
								onClose={handleSwapClose}
							>
								<MenuItem onClick={handleSwapPlayer1AndPlayer3}>
									Swap {player1}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer2AndPlayer3}>
									Swap {player2}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer3AndPlayer4}>
									Swap {player4}
								</MenuItem>
							</Menu>
						</div>
					</CardActions>
				</Card>
				{team.d3DeathTitle != null && thirdDeathCard()}
			</div>
		);
	}

	function thirdDeathCard() {
		return (
			<Card className={classes.root}>
				<CardContent>
					<img width="50" height="60" src={imageDeath} />
					<Typography className={classes.pos} color="textSecondary">
						{team.d3DeathTitle}
					</Typography>
					<Typography variant="body2" component="p">
						{team.d3DeathDescription}
					</Typography>
					<img width="200" height="200" src={imageD3} />
				</CardContent>
			</Card>
		);
	}

	function fourthCard() {
		return (
			<div>
				<Card
					className={team.d4status ? classes.fillCompleted : classes.fill}
					variant="outlined"
				>
					<CardContent>
						<img width="50" height="60" src={imageDrink} />
						<Grid container spacing={3}>
							<Grid></Grid>
							<Switch
								checked={switchState.checked4}
								onChange={handleSwitch}
								color="primary"
								name="checked4"
								inputProps={{ "aria-label": "primary checkbox" }}
							/>
						</Grid>
						<Typography variant="h5" component="h2">
							{team.d4title}
						</Typography>
						<Typography className={classes.pos} color="textSecondary">
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={subtractP4Value}
								disabled={!switchState.checked4 || team.d4status}
							>
								<ExposureNeg1Icon />
							</IconButton>
							{team.d4value} point(s)
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={addP4Value}
								disabled={!switchState.checked4 || team.d4status}
							>
								<PlusOneIcon />
							</IconButton>
						</Typography>
						<Typography variant="body2" component="p">
							{team.d4description}
						</Typography>
						<img width="200" height="200" src={image4} />
					</CardContent>
					<CardActions>
						<div>
							<Button
								className={classes.stickToLeft}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleOptionsClick}
							>
								Open Menu
							</Button>
							<Menu
								id="options-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleOptionsClose}
							>
								<MenuItem
									onClick={handleD4CompleteDrink}
									disabled={team.d4status}
								>
									Set card to complete
								</MenuItem>
								<MenuItem
									onClick={handleD4UnCompleteDrink}
									disabled={!team.d4status}
								>
									Set card to incomplete
								</MenuItem>
							</Menu>
							<Button
								className={classes.stickToRight}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={handleSwapClick}
							>
								Swap Menu
							</Button>
							<Menu
								id="swap-menu"
								anchorEl={anchorElSwap}
								keepMounted
								open={Boolean(anchorElSwap)}
								onClose={handleSwapClose}
							>
								<MenuItem onClick={handleSwapPlayer1AndPlayer4}>
									Swap {player1}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer2AndPlayer4}>
									Swap {player2}
								</MenuItem>
								<MenuItem onClick={handleSwapPlayer3AndPlayer4}>
									Swap {player3}
								</MenuItem>
							</Menu>
						</div>
					</CardActions>
				</Card>
				{team.d4DeathTitle != null && fourthDeathCard()}
			</div>
		);
	}

	function fourthDeathCard() {
		return (
			<Card className={classes.root}>
				<CardContent>
					<img width="50" height="60" src={imageDeath} />
					<Typography className={classes.pos} color="textSecondary">
						{team.d4DeathTitle}
					</Typography>
					<Typography variant="body2" component="p">
						{team.d4DeathDescription}
					</Typography>
					<img width="200" height="200" src={imageD4} />
				</CardContent>
			</Card>
		);
	}

	function emptyCard() {
		return <div>Game has not yet started</div>;
	}

	function showTeam() {
		return (
			<div>
				<Divider />
				<div className={classes.root}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Typography variant="body1" component="h2">
								<IconButton
									className={classes.iconButton}
									aria-label="search"
									onClick={subtractScoreValue}
									disabled={!switchState.checkedHome}
								>
									<ExposureNeg1Icon />
								</IconButton>
								Score: {team.score}
								<IconButton
									className={classes.iconButton}
									aria-label="search"
									onClick={addScoreValue}
									disabled={!switchState.checkedHome}
								>
									<PlusOneIcon />
								</IconButton>
							</Typography>
							<Switch
								checked={switchState.checkedHome}
								onChange={handleSwitch}
								color="primary"
								name="checkedHome"
								inputProps={{ "aria-label": "primary checkbox" }}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="body1" component="h2">
								Round: {team.round}
							</Typography>
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={getNewDrinks}
							>
								Next Round
								<ArrowForwardIosIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						{/* <Grid item xs={7}></Grid>
						<Grid item xs={5}>
							<IconButton
								className={classes.iconButton}
								aria-label="search"
								onClick={getNewDrinks}
							>
								Next Round
								<ArrowForwardIosIcon />
							</IconButton>
						</Grid> */}
					</Grid>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Typography variant="h3" component="h2">
								{team.name}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Typography variant="body1" component="h2">
								Search code: {team.searchTerm}
							</Typography>
						</Grid>
					</Grid>
				</div>
				<div classname={classes.root}>
					<ImageList className={classes.imageList} rowHeight="300" cols={1.5}>
						{Object.keys(itemData).map((item) => (
							<ImageListItem key={itemData[item].title}>
								<img
									width="300"
									height="300"
									src={itemData[item].img}
									alt={itemData[item].title}
								/>
								<ImageListItemBar
									title={itemData[item].title}
									classes={{
										root: classes.titleBar,
										title: classes.subtitle,
										subtitle: classes.subtitle,
									}}
									actionIcon={
										<IconButton
											name={item}
											aria-label={`star ${itemData[item].title}`}
											onClick={updateActionStatus}
										>
											<CheckCircleOutlineIcon className={classes.title} />
										</IconButton>
									}
									subtitle={
										itemData[item].state.toString() !== "true"
											? itemData[item].description
											: "Completed"
									}
								/>
							</ImageListItem>
						))}
					</ImageList>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div>
				{value === "home" && showTeam()}
				{value !== "home" && team.status === "Create" && emptyCard()}
				{value === "player1" && team.status === "In Progress" && firstCard()}
				{value === "player2" && team.status === "In Progress" && secondCard()}
				{value === "player3" && team.status === "In Progress" && thirdCard()}
				{value === "player4" && team.status === "In Progress" && fourthCard()}
			</div>
			<BottomNavigation
				value={value}
				onChange={handleChange}
				className={classes.stickToBottom}
			>
				<BottomNavigationAction
					label={player1}
					value="player1"
					icon={<Filter1Icon />}
				/>
				<BottomNavigationAction
					label={player2}
					value="player2"
					icon={<Filter2Icon />}
				/>
				<BottomNavigationAction
					label={player3}
					value="player3"
					icon={<Filter3Icon />}
				/>
				<BottomNavigationAction
					label={player4}
					value="player4"
					icon={<Filter4Icon />}
				/>
				<BottomNavigationAction
					label="team"
					value="home"
					icon={<GroupIcon />}
				/>
			</BottomNavigation>
		</div>
	);
}

export default Party;
